exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-crm-js": () => import("./../../../src/pages/crm.js" /* webpackChunkName: "component---src-pages-crm-js" */),
  "component---src-pages-cs-js": () => import("./../../../src/pages/cs.js" /* webpackChunkName: "component---src-pages-cs-js" */),
  "component---src-pages-edu-js": () => import("./../../../src/pages/edu.js" /* webpackChunkName: "component---src-pages-edu-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resource-js": () => import("./../../../src/pages/resource.js" /* webpackChunkName: "component---src-pages-resource-js" */),
  "component---src-pages-uts-js": () => import("./../../../src/pages/uts.js" /* webpackChunkName: "component---src-pages-uts-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

