import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const LogoStyle = styled.div`
    width: 340px;
    height: 48px;
    background: url("https://mtimg.onemiao.cn/web-images/logo.png") left center no-repeat;
    background-size: cover;
    @media (max-width: 768px) {
        width: 250px;
        background-size: contain;
    }
`

const NavStyle = styled.div`
    width: 100%;
    background-color: white;
    height: 80px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    .top-bar {
        width: 100%;
        height: 80px;
        display: flex;
        padding: 0 40px;
        justify-content: space-between;
        align-items: center;
    }
    @media (max-width: 768px) {
        height: 60px;
        .top-bar {
            height: 60px;
            padding: 0 8px;
        }
    }
`

const NavBarStyle = styled.div`
    display: flex;
    align-items: center;
    .nav-item {
        margin-left: 20px;
        width: 80px;
        display: flex;
        justify-content: center;
        a {
            font-size: 16px;
            color: var(--dark);
            :hover {
                color: var(--primary-color);
            }
        }
        .current {
            color: var(--primary-color);
            font-weight: bold;
        }
    }
    .nav-item.user-manual {
        width: 100px;
        a {
            width: 100%;
            text-align: center;
            line-height: 32px;
            background-color: var(--primary-color);
            color: white;
            height: 32px;
            border-radius: 16px;
            :hover {
                background-color: var(--deepGreen);
            }
        }
    }
    .nav-products {
        width: 80px;
        margin-left: 20px;
        position: relative;
        h3 {
            width: 100%;
            line-height: 32px;
            text-align: center;
            color: var(--dark);
        }
        .nav-product-links {
            position: absolute;
            top: 32px;
            left: 0;
            width: 100px;
            background-color: white;
            border-radius: 10px;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
            display: none;
            flex-direction: column;
            align-items: flex-start;
            padding: 5px 10px 5px 15px;
            a {
                width: 100%auto;
                text-align: left;
                line-height: 32px;
                font-size: 16px;
                margin: 5px 0;
                span {
                    font-weight: bold;
                    margin-left: 4px;
                }
            }
            a.current {
                color: var(--primary-color);
                font-weight: bold;
            }
        }
        :hover {
            h3 {
                color: var(--primary-color);
                font-weight: bold;
            }
            .nav-product-links {
                display: flex;
            }
        }
    }
    .nav-products.current {
        h3 { 
            color: var(--primary-color); 
            font-weight: bold;
        }
    }
    @media (max-width: 1200px) {
        flex-direction: column;
        position: fixed;
        top: 70px;
        right: 20px;
        width: 200px;
        background-color: white;
        border: 1px solid var(--lightGray);
        border-radius: 10px;
        z-index: 3;
        padding: 10px 20px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
        transform: ${({nav}) => nav ? "translateX(0)" : "translateX(120%)"};
        transition: transform 0.3s ease-in-out;
        .nav-item {
            margin: 0;
            width: 100%;
            padding: 10px 0;
            border-bottom: 1px solid var(--lightGray);
            :last-child {
                border-bottom: none;
            }
        }
        .nav-products {
            margin-left: 0;
            width: 100%;
            padding: 0;
            h3 {
                display: none;
            }
            .nav-product-links {
                display: flex;
                position: static;
                border-radius: 0;
                box-shadow: none;
                padding: 0;
                width: 100%;
                a {
                    width: 100%;
                    text-align: center;
                    padding: 10px 0;
                    border-bottom: 1px solid var(--lightGray);
                    margin: 0;
                    line-height: unset;
                    color: var(--dark);
                }
            }
        }
    }
    @media (max-width: 768px) {
        width: 100%;
        border-radius: 0;
        border: none;
        box-shadow: none;
        right: 0;
        top: 60px;
        padding: 0 40px;
        .nav-item {
            padding: 20px 0;
        }
        .nav-products {
            .nav-product-links a {
                padding: 20px 0;
            }
        }
    }
`

const MenuIconStyle = styled.div`
    width: 60px;
    height: 60px;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    div {
        width: 24px;
        height: 4px;
        border-radius: 2px;
        margin: 2px 0;
        background-color: var(--dark);
        transform-origin: 1px;
        position: relative;
        transition: transform 0.1s ease-in-out;

        :first-child { 
            transform: ${({ nav }) => nav ? 'rotate(45deg)' : 'rotate(0deg)'}; 
        }
        :nth-child(2) {
            opacity: ${({ nav }) => nav ? '0%' : '100%'};
        }
        :last-child {
            transform: ${({ nav }) => nav ? 'rotate(-45deg)' : 'rotate(0deg)'};
        }
    }
    @media (max-width: 1200px) {
        display: flex;
    }
    @media (max-width: 768px) {
        width: 32px;
        height: 24px;
        justify-content: space-around;
        div {
            height: 2px;
        }
    }
`

export default function Nav(path) {
    console.log(path);

    let isCurrent = false;
    const productLinks = [
        '/crm/',
        '/uts/',
        '/edu/'
    ]
    productLinks.forEach(link => {
        if (path.currentPage === link) {
            isCurrent = true;
        }
    })

    const [nav, showNav] = useState(false);

    return <NavStyle>
        <div className="content-wrap">
            <div className="top-bar">
                <Link to="/"><LogoStyle /></Link>
                <NavBarStyle nav={nav}>
                    <div className="nav-item"><Link to="/" activeClassName="current" onClick={() => showNav(!nav)}>首页</Link></div>
                    <div className={isCurrent ? "nav-products current" : "nav-products"}>
                        <h3>苗通产品</h3>
                        <div className="nav-product-links">
                            <Link to="/crm" activeClassName="current" onClick={() => showNav(!nav)}>苗通<span>CRM</span></Link>
                            <Link to="/uts" activeClassName="current" onClick={() => showNav(!nav)}>苗通<span>UTS</span></Link>
                            <Link to="/edu" activeClassName="current" onClick={() => showNav(!nav)}>苗通<span>EDU</span></Link>
                        </div>
                    </div>
                    <div className="nav-item"><Link to="/cs" activeClassName="current" onClick={() => showNav(!nav)}>客户成功</Link></div>
                    <div className="nav-item"><Link to="/resource" activeClassName="current" onClick={() => showNav(!nav)}>资源中心</Link></div>
                    <div className="nav-item"><a href="https://youmingw.flowus.cn" target="_blank" rel="noreferrer">用户中心</a></div>
                    <div className="nav-item"><Link to="/about" activeClassName="current" onClick={() => showNav(!nav)}>关于安通</Link></div>
                </NavBarStyle>
                <MenuIconStyle nav={nav} onClick={() => showNav(!nav)}>
                    <div></div>
                    <div></div>
                    <div></div>
                </MenuIconStyle>
            </div>            
        </div>
    </NavStyle>
}