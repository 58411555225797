import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    :root {
        --primary-color: #00C885;
        --secondary-color: #FF9E00;
        --blue: #00A0E9;
        --deepBlue: #0053A6;
        --deepGreen: #03AA71;
        --red: #FF3F25;
        --dark: #3E3A39;
        --lightGray: #f4f4f4;
        --gray: #9A9A9A;
    }

    * {
        box-sizing: border-box;
    }

    h1, h2, h3, h4, h5, p {
        font-weight: normal;
        margin: 0;
    }

    h1 {
        font-size: 32px;
    }

    h2 {
        font-size: 24px;
    }

    h3 {
        font-size: 16px;
    }

    h4 {
        font-size: 14px;
    }

    h5 {
        font-size: 12px;
    }

    p {
        font-size: 14px;
    }

    a {
        text-decoration: none;
        font-size: 14px;
        color: black;
    }

    @media (max-width: 768px) {
        h1 {
            font-size: 24px;
        }
        h2 {
            font-size: 18px;
        }
        h3 {
            font-size: 14px;
        }
        h4 {
            font-size: 12px;
        }
        p {
            font-size: 12px;
        }
    }

    .content-wrap {
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        @media (max-width: 768px) {
            padding: 0 10px;
        }
    }

    .show-more-resource {
        cursor: pointer;
        width: 180px;
        height: 60px;
        border-radius: 30px;
        background: var(--primary-color) url(https://mtimg.onemiao.cn/web-images/icon_arrow_right24.svg) right 16px center no-repeat;
        background-size: 24px;
        color: white;
        font-size: 24px;
        line-height: 60px;
        text-indent: 32px;
        box-shadow: 0px 4px 4px rgb(0 0 0 / 10%);
        margin-top: 40px;
    }

    .section-base-row {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 120px 20px 80px;
        h1 {
            font-weight: bold;
            text-align: center;
            margin-bottom: 40px;
        }
        @media (max-width: 800px) {
            padding: 60px 10px 40px;
        }
    }

    .section-head {
        width: 100%;
        max-width: 1200px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .section-head-img {
            flex: 1;
            max-width: 620px;
        }
        .section-info {
            max-width: 450px;
            h2 {
                font-weight: bold;
            }
            h3 {
                margin-top: 10px;
                color: var(--dark);
            }
            .section-title {
                font-size: 32px;
                font-weight: bold;
                margin-bottom: 10px;
                color: var(--primary-color);
            }
            .function-group {
                margin-top: 30px;
                .function-icon-row {
                    width: 100%;
                    display: flex;
                    margin-top: 20px;
                    span {
                        display: block;
                        width: 80px;
                        padding-top: 38px;
                        text-align: center;
                        margin-right: 20px;
                        color: var(--dark);
                    }
                }
            }
        }
        @media (max-width: 800px) {
            flex-direction: column;
            align-items: center;
            .section-info {
                margin-bottom: 40px;
                .section-title {
                    font-size: 24px;
                }
                .function-group {
                    .function-icon-row {
                        width: 100%;
                        justify-content: space-between;
                        align-items: stretch;
                        span {
                            font-size: 14px;
                            background-size: 24px;
                            width: unset;
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
    .section-head.crm1 {
        align-items: flex-end;
        .section-info {
            .function-group {
                .function-icon-row {
                    :nth-child(2) {
                        span:nth-child(1) {
                            background: url("https://mtimg.onemiao.cn/web-images/crm/crm_icon_f1_1.svg") top center no-repeat;
                            background-size: 32px;
                        }
                        span:nth-child(2) {
                            background: url("https://mtimg.onemiao.cn/web-images/crm/crm_icon_f1_2.svg") top center no-repeat;
                            background-size: 32px;
                        }
                        span:nth-child(3) {
                            background: url("https://mtimg.onemiao.cn/web-images/crm/crm_icon_f1_3.svg") top center no-repeat;
                            background-size: 32px;
                        }
                        span:nth-child(4) {
                            background: url("https://mtimg.onemiao.cn/web-images/crm/crm_icon_f1_4.svg") top center no-repeat;
                            background-size: 32px;
                        }
                    }
                    :nth-child(3) {
                        span:nth-child(1) {
                            background: url("https://mtimg.onemiao.cn/web-images/crm/crm_icon_f1_5.svg") top center no-repeat;
                            background-size: 32px;
                        }
                        span:nth-child(2) {
                            background: url("https://mtimg.onemiao.cn/web-images/crm/crm_icon_f1_6.svg") top center no-repeat;
                            background-size: 32px;
                        }
                        span:nth-child(3) {
                            background: url("https://mtimg.onemiao.cn/web-images/crm/crm_icon_f1_7.svg") top center no-repeat;
                            background-size: 32px;
                        }
                        span:nth-child(4) {
                            background: url("https://mtimg.onemiao.cn/web-images/crm/crm_icon_f1_8.svg") top center no-repeat;
                            background-size: 32px;
                        }
                    }
                }
            }
        }
        @media (max-width: 800px) {
            align-items: center;
        }
    }
    .section-head.crm2 {
        .section-info {
            .function-group {
                .function-icon-row {
                    :nth-child(2) {
                        span:nth-child(1) {
                            background: url("https://mtimg.onemiao.cn/web-images/crm/crm_icon_f2_1.svg") top center no-repeat;
                            background-size: 32px;
                        }
                        span:nth-child(2) {
                            background: url("https://mtimg.onemiao.cn/web-images/crm/crm_icon_f2_2.svg") top center no-repeat;
                            background-size: 32px;
                        }
                        span:nth-child(3) {
                            background: url("https://mtimg.onemiao.cn/web-images/crm/crm_icon_f2_3.svg") top center no-repeat;
                            background-size: 32px;
                        }
                        span:nth-child(4) {
                            background: url("https://mtimg.onemiao.cn/web-images/crm/crm_icon_f2_4.svg") top center no-repeat;
                            background-size: 32px;
                        }
                    }
                    :nth-child(3) {
                        span:nth-child(1) {
                            background: url("https://mtimg.onemiao.cn/web-images/crm/crm_icon_f2_5.svg") top center no-repeat;
                            background-size: 32px;
                        }
                        span:nth-child(2) {
                            background: url("https://mtimg.onemiao.cn/web-images/crm/crm_icon_f2_6.svg") top center no-repeat;
                            background-size: 32px;
                        }
                        span:nth-child(3) {
                            background: url("https://mtimg.onemiao.cn/web-images/crm/crm_icon_f2_7.svg") top center no-repeat;
                            background-size: 32px;
                        }
                        span:nth-child(4) {
                            background: url("https://mtimg.onemiao.cn/web-images/crm/crm_icon_f2_8.svg") top center no-repeat;
                            background-size: 32px;
                        }
                    }
                }
            }
        }
        @media (max-width: 800px) {
            flex-direction: column-reverse;
            .section-info {
                padding: 0 10px;
            }
        }
    }
    .section-head.crm3 {
        align-items: flex-end;
        .section-info {
            .function-group {
                .function-icon-row {
                    :nth-child(2) {
                        span:nth-child(1) {
                            background: url("https://mtimg.onemiao.cn/web-images/crm/crm_icon_f3_1.svg") top center no-repeat;
                            background-size: 32px;
                        }
                        span:nth-child(2) {
                            background: url("https://mtimg.onemiao.cn/web-images/crm/crm_icon_f3_2.svg") top center no-repeat;
                            background-size: 32px;
                        }
                        span:nth-child(3) {
                            background: url("https://mtimg.onemiao.cn/web-images/crm/crm_icon_f3_3.svg") top center no-repeat;
                            background-size: 32px;
                        }
                        span:nth-child(4) {
                            background: url("https://mtimg.onemiao.cn/web-images/crm/crm_icon_f3_4.svg") top center no-repeat;
                            background-size: 32px;
                        }
                    }
                    :nth-child(3) {
                        span:nth-child(1) {
                            background: url("https://mtimg.onemiao.cn/web-images/crm/crm_icon_f3_5.svg") top center no-repeat;
                            background-size: 32px;
                        }
                        span:nth-child(2) {
                            background: url("https://mtimg.onemiao.cn/web-images/crm/crm_icon_f3_6.svg") top center no-repeat;
                            background-size: 32px;
                        }
                        span:nth-child(3) {
                            background: url("https://mtimg.onemiao.cn/web-images/crm/crm_icon_f3_7.svg") top center no-repeat;
                            background-size: 32px;
                        }
                        span:nth-child(4) {
                            background: url("https://mtimg.onemiao.cn/web-images/crm/crm_icon_f3_8.svg") top center no-repeat;
                            background-size: 32px;
                        }
                    }
                }
            }
        }
        @media (max-width: 800px) {
            align-items: center;
            .section-info {
                padding: 0 10px;
            }
        }
    }

    .section-head.edu1 {
        justify-content: center;
        .section-head-img {
            max-width: 460px;
            margin-left: 80px;
        }
        .section-title {
            color: var(--blue);
        }
        .section-info .function-group .function-icon-row {
            span:nth-child(1) {
                background: url("https://mtimg.onemiao.cn/web-images/edu/edu_cf1_icon1.svg") top center no-repeat;
                background-size: 32px;
            }
            span:nth-child(2) {
                background: url("https://mtimg.onemiao.cn/web-images/edu/edu_cf1_icon2.svg") top center no-repeat;
                background-size: 32px;
            }
            span:nth-child(3) {
                background: url("https://mtimg.onemiao.cn/web-images/edu/edu_cf1_icon3.svg") top center no-repeat;
                background-size: 32px;
            }
            span:nth-child(4) {
                background: url("https://mtimg.onemiao.cn/web-images/edu/edu_cf1_icon4.svg") top center no-repeat;
                background-size: 32px;
            }
        }
        @media (max-width: 800px) {
            .section-head-img {
                width: 100%;
                max-width: unset;
                margin: 0;
            }
            .section-info {
                padding: 0 10px;
            }
        }
    }
    .section-head.edu2 {
        justify-content: center;
        .section-head-img {
            margin-right: 60px;
        }
        .section-title {
            color: var(--blue);
        }
        .section-info .function-group .function-icon-row {
            span:nth-child(1) {
                background: url("https://mtimg.onemiao.cn/web-images/edu/edu_cf2_icon1.svg") top center no-repeat;
                background-size: 32px;
            }
            span:nth-child(2) {
                background: url("https://mtimg.onemiao.cn/web-images/edu/edu_cf2_icon2.svg") top center no-repeat;
                background-size: 32px;
            }
            span:nth-child(3) {
                background: url("https://mtimg.onemiao.cn/web-images/edu/edu_cf2_icon3.svg") top center no-repeat;
                background-size: 32px;
            }
            span:nth-child(4) {
                background: url("https://mtimg.onemiao.cn/web-images/edu/edu_cf2_icon4.svg") top center no-repeat;
                background-size: 32px;
            }
        }
        @media (max-width: 800px) {
            .section-head-img {
                width: 100%;
                max-width: unset;
                margin: 0;
                margin-bottom: 40px;
            }
            .section-info {
                padding: 0 10px;
                margin-bottom: 0;
            }
        }
    }
    .section-head.edu3 {
        justify-content: center;
        .section-head-img {
            max-width: 540px;
            margin-left: 60px;
        }
        .section-title {
            color: var(--blue);
        }
        .section-info .function-group .function-icon-row {
            span:nth-child(1) {
                background: url("https://mtimg.onemiao.cn/web-images/edu/edu_cf3_icon1.svg") top center no-repeat;
                background-size: 32px;
            }
            span:nth-child(2) {
                background: url("https://mtimg.onemiao.cn/web-images/edu/edu_cf3_icon2.svg") top center no-repeat;
                background-size: 32px;
            }
            span:nth-child(3) {
                background: url("https://mtimg.onemiao.cn/web-images/edu/edu_cf3_icon3.svg") top center no-repeat;
                background-size: 32px;
            }
            span:nth-child(4) {
                background: url("https://mtimg.onemiao.cn/web-images/edu/edu_cf3_icon4.svg") top center no-repeat;
                background-size: 32px;
            }
        }
        @media (max-width: 800px) {
            .section-head-img {
                width: 100%;
                max-width: unset;
                margin: 0;
            }
            .section-info {
                padding: 0 10px;
            }
        }
    }

    .section-cards-wrap {
        width: 100%;
        padding: 60px 20px 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        h2 {
            text-align: center;
            margin-bottom: 20px;
            font-weight: bold;
        }
        .card-group {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: stretch;
            .function-card {
                background-color: white;
                border-radius: 10px;
                margin: 0 20px;
                padding: 20px;
                h2 {
                    text-align: center;
                    margin-bottom: 5px;
                    font-weight: bold;
                }
                h3, p {
                    color: var(--dark);
                }
                .function-graphic {
                    width: 100%;
                    img {
                        width: 100%;
                    }
                }
            }
        }
        @media (max-width: 640px) {
            .card-group {
                flex-direction: column;
                align-items: center;
                .function-card {
                    margin: 20px 0;
                }
            }
        }
    }
    .section-cards-wrap.gray {
        background: var(--lightGray);
    }
    .section-cards-wrap.crm1 .card-group {
        .function-card {
            width: 320px;
            h2 {
                margin-top: 40px;
            }
        }
    }
    .section-cards-wrap.crm2 .card-group {
        .function-card {
            width: 320px;
            h2 {
                margin-top: 20px;
            }
        }
    }
    .section-cards-wrap.edu1 .card-group {
        .function-card {
            width: 320px;
            h2 {
                margin-top: 20px;
                font-size: 20px;
            }
        }
    }
    @media (max-width: 640px) {
        .section-cards-wrap.crm1, .section-cards-wrap.crm2 {
            .card-group .function-card {
                width: 100%;
            }
        }
    }
`;

export default GlobalStyles;