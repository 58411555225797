import React from "react";
import styled from "styled-components";
import GlobalStyles from "../style/GlobalStyles";
import Footer from "./Footer";
import Nav from "./Nav";
import SEO from "./SEO";

const PageStyle = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 80px;
    .main {
        flex: 1;
        width: 100%;
    }
    @media (max-width: 768px) {
        padding-top: 60px;
    }
`

export default function Layout({ path, children }) {
    return <div>
        <SEO />
        <GlobalStyles />
        <PageStyle>
            <Nav currentPage={path} />
            <div className="main">
                { children }
            </div>
            <Footer />
        </PageStyle>
    </div>
}