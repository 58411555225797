import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const FooterStyle = styled.div`
    width: 100%;
    background-color: var(--lightGray);
    padding: 40px 0;
    .content-wrap {
        max-width: 1000px;
    }
    .footer-links {
        width: 100%;
        padding: 0 20px 20px;
        border-bottom: 1px solid var(--dark);
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        .link-group {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            position: relative;
            h3 {
                font-weight: bold;
                margin-bottom: 20px;
            }
            a {
                line-height: 24px;
                margin-bottom: 8px;
                display: block;
                span {
                    font-weight: bold;
                    margin-left: 4px;
                }
                span.crm {
                    color: var(--primary-color);
                }
                span.uts {
                    color: var(--secondary-color);
                }
                span.edu {
                    color: var(--blue);
                }
            }
            .wechat-link {
                font-size: 14px;
                line-height: 24px;
                background: url("https://mtimg.onemiao.cn/web-images/icon_wechat.svg") left center no-repeat;
                background-size: 24px;
                padding-left: 32px;
                margin-bottom: 8px;
                position: relative;
                cursor: pointer;
                .qrcode {
                    padding: 10px;
                    border-radius: 6px;
                    background-color: white;
                    position: absolute;
                    bottom: 24px;
                    left: 0;
                    z-index: 2;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
                    visibility: hidden;
                    .qrcode-img {
                        width: 80px;
                        height: 80px;
                        background: var(--lightGray) url("https://mtimg.onemiao.cn/web-images/wechat_im.jpeg") center no-repeat;
                        background-size: 80px;
                        margin-bottom: 8px;
                    }
                    h4 {
                        text-align: center;
                    }
                }
                :hover {
                    .qrcode {
                        visibility: visible;
                    }
                }
            }
            .weibo-link {
                background: url("https://mtimg.onemiao.cn/web-images/icon_weibo.svg") left center no-repeat;
                padding-left: 32px;
                background-size: 24px;
            }
            .phone-call {
                background: url("https://mtimg.onemiao.cn/web-images/icon_phone.svg") left center no-repeat;
                padding-left: 32px;
                background-size: 24px;
            }
        }

        @media (max-width: 480px) {
            flex-wrap: wrap;
            padding: 0 10px;
            .link-group {
                width: 50%;
                margin-bottom: 40px;
                align-items: center;
            }
        }
    }
    p {
        font-size: 12px;
        line-height: 20px;
        color: var(--gray);
    }
`

export default function Footer() {
    return <FooterStyle>
        <div className="content-wrap">
            <div className="footer-links">
                <div className="link-group">
                    <h3>产品</h3>
                    <Link to="/crm">苗通<span className="crm">CRM</span></Link>
                    <Link to="/uts">苗通<span className="uts">UTS</span></Link>
                    <Link to="/edu">苗通<span className="edu">EDU</span></Link>
                </div>
                <div className="link-group">
                    <h3>资源</h3>
                    <Link to="/resource">干货内容</Link>
                    <Link to="/cs">成功服务</Link>
                    <Link to="/resource" state={{ filterTags: ["应用案例"] }}>客户案例</Link>
                </div>
                <div className="link-group">
                    <h3>帮助中心</h3>
                    <Link to="/">更新日志</Link>
                    <a href="https://youmingw.flowus.cn" target="_blank" rel="noreferrer">产品手册</a>
                    <Link to="/">社区交流</Link>
                </div>
                <div className="link-group">
                    <h3>关注我们</h3>
                    <div className="wechat-link">
                        微信公号
                        <div className="qrcode">
                            <div className="qrcode-img"></div>
                            <h4>扫一扫<br />关注我们</h4>
                        </div>
                    </div>
                    <a className="weibo-link" href="https://weibo.com">新浪微博</a>
                    <a href="tel:400-810-9830" className="phone-call">联系我们</a>
                </div>
            </div>
            <p>Copyright © 2022 北京安通康泰健康科技有限公司 版权所有<br />增值电信业务经营许可证编号：京B2-20221294</p>
        </div>
    </FooterStyle>
}
